import SEO from 'components/seo';

import WalletStart from 'components/wallet-start/wallet-start';
import Balance from 'components/balance/balance';
import Charts from 'components/charts/charts';
import HashrateStats from 'components/charts/hashrate-stats/hashrate-stats';
import DailyStatistic from 'components/table-block/daily-statistic/daily-statistic';

import {
  MainContentArea,
  Wrapper,
  Container,
  Row
} from 'assets/styles/pages.style';

export default function MinerStatsPage() {
  return (
    <MainContentArea>
      <Container>
        <WalletStart />
        <Row>
          <Balance loader={false} />
        </Row>
        <Row>
          <Wrapper>
            <Charts loading={false} />
            <HashrateStats loading={false} />
          </Wrapper>
        </Row>
        <Row px={[0, 0, 4]}>
          <DailyStatistic loading={false} />
        </Row>
      </Container>
    </MainContentArea>
  );
}

export const Head = () => {
  return <SEO title="Miner Stats" />;
};
